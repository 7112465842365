<template>
  <b-modal
    id="showEmailPreview"
    size="lg"
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="mailbox"></b-icon>
        <span>Preview Email</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>
    <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn @click="cancel()" size="sm" variant="outline-secondary"
          >Chiudi</b-btn
        >
      </b-form-group>
    </template>
    <b-card header="Email" header-tag="header" class="mb-2">
      <b-card-text>
        <p class="subject">{{ subject }}</p>
        <hr />
        <div v-html="email"></div>
      </b-card-text>
    </b-card>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    subject: {
      type: String,
    },
    email: {
      type: String,
    },
  },
};
</script>

<style scoped>
.subject {
  font-weight: bold;
  font-size: 1.5em;
}
</style>
