<template>
  <div class="mt-1 mx-3">
    <emails-create></emails-create>
  </div>
</template>

<script>
import EmailsCreate from "@/components/profile/lisacomunica/emails/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    EmailsCreate,
  },
  created() {
    this.notifyQueue({
      text: "Emails",
      path: "lisacomunica_template",
      tab: "Emails",
      level: 3,
    });
    this.notifyQueue({
      text: "Inserisci template email",
      path: "emails.create",
      level: 4,
    });
  },
};
</script>
